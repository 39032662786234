<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    temporary
  >
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    items: [
      { icon: "mdi-inbox", text: "Messages", to: "/messages" },
      { icon: "mdi-inbox", text: "Mail", to: "/mail" },
      { icon: "mdi-tools", text: "Backorders", to: "/backorders" },
      { icon: "mdi-tools", text: "Domains", to: "/domains" },
      { icon: "mdi-content-copy", text: "Templates", to: "/templates" },
      { icon: "mdi-content-copy", text: "Classification", to: "/classification" },
      { icon: "mdi-tools", text: "Bulk Tools", to: "/bulktools" },
      { icon: "mdi-tools", text: "Leads Review", to: "/leads" },
      { icon: "mdi-database-arrow-right", text: "Statistics", to: "/stats" },
      { icon: "mdi-calendar", text: "Inventory", to: "/inventory" },
    ],
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
};
</script>
